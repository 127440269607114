<div [formGroup]="form" [ngClass]="{ 'has-danger': hasDanger }">
  <dl-multiselect
    [items]="items"
    [filteredEmployees]="filteredScheduledEmployees"
    [availableEmployees]="availableEmployees"
    [config]="{
      hasScheduledOnlyCheckBox: !!filteredScheduledEmployees,
      hasAvailableOnlyCheckBox: !!availableEmployees,
      hasAllCheckBox: true,
      hasFilter: true,
      hasCollapseExpand: true,
      maxHeight
    }"
    [itemTemplate]="employeeItem"
    [formControlName]="name"
  ></dl-multiselect>

  <control-messages [control]="form.get(name)"></control-messages>

  <ng-template
    #employeeItem
    let-item="item"
    let-onCollapseExpand="onCollapseExpand"
    let-onCheckedChange="onCheckedChange"
  >
    <div
      [ngClass]="{
        'select-employee': !item.children && employees[item.value],
        'select-team': item.children && teams[item.value]
      }"
    >
      <div *ngIf="item.children && teams[item.value]; let team" (click)="itemClicked(onCheckedChange, item)">
        <strong>{{ team.name }}</strong>
      </div>

      <div
        class="avatar-item"
        [ngClass]="{
          selected: item.checked,
          conflicted: item.disabled
        }"
        *ngIf="!item.children && employees[item.value]; let employee"
        (click)="itemClicked(onCheckedChange, item)"
      >
        <div class="avatar-item__container">
          <sb-avatar [employee]="employee" [size]="30"></sb-avatar>
        </div>

        <div class="avatar-item__details">
          <span class="avatar-item__title">{{ employee.name }}</span>
          <span *ngIf="item.disabled && disabledMessage" class="avatar-item__subtitle">{{ disabledMessage }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
