import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';

import { ControlMessages } from '../../forms/control-messages.component';
import { AppState } from '../../reducers/index';
import { EmployeeModel } from '../../reducers/orm/employee/employee.model';
import { getEmployeeEntities } from '../../reducers/orm/employee/employee.service';
import { TeamModel } from '../../reducers/orm/team/team.model';
import { getTeamEntities } from '../../reducers/orm/team/team.service';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { TreeviewItem } from '../ngx-treeview/treeview-item';
import { SBAvatarComponent } from '../sb-lib/avatar/sb-avatar.component';

@Component({
  selector: 'dl-select-employees',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './select-employees.component.html',
  standalone: true,
  imports: [CommonModule, MultiselectComponent, ReactiveFormsModule, ControlMessages, SBAvatarComponent],
})
export class SelectEmployeesComponent implements OnInit, OnDestroy {
  @ViewChild(MultiselectComponent, { static: true })
  public selectComponent: MultiselectComponent;
  @ViewChild(ControlMessages, { static: true })
  public controlMessages: ControlMessages;

  @Input()
  public items: TreeviewItem[];
  @Input()
  public availableEmployees: Set<string>;
  @Input()
  public filteredScheduledEmployees: Set<string>;
  @Input()
  public name: string;
  @Input()
  public form: UntypedFormGroup;
  @Input()
  public disabledMessage: string;
  @Input()
  public maxHeight = 450;

  public employees: { [id: string]: EmployeeModel } = {};
  private employeesSubscription;

  public teams: { [id: string]: TeamModel } = {};
  private teamsSubscription;

  public constructor(private store: Store<AppState>) {}

  public ngOnInit() {
    this.employeesSubscription = this.store
      .select(getEmployeeEntities)
      .subscribe((employees) => (this.employees = employees));

    this.teamsSubscription = this.store.select(getTeamEntities).subscribe((teams) => (this.teams = teams));
  }

  public ngOnDestroy() {
    this.employeesSubscription.unsubscribe();
    this.teamsSubscription.unsubscribe();
  }

  public get hasDanger() {
    return this.controlMessages.showError;
  }

  public itemClicked(onCheckedChange, item) {
    item.checked = !item.checked;
    onCheckedChange();
  }
}
